import bg from '../assets/bg.png';
import gericht from '../assets/gericht.png';
import overlaybg from '../assets/overlaybg.png';
import logo from '../assets/logo.png';
import spoon from '../assets/spoon.png'

export default {
  bg,
  gericht,
  logo,
  overlaybg,
  spoon,
};
